import { Session } from "@supabase/supabase-js"
import { useEffect } from "react"
import { supabase } from "../api"
import useError from "../hooks/useError"

async function setSessionUp(setSession: Function, handleError: (error: Error) => void) {
    const { data: { session }, error } = await supabase.auth.getSession()
    if (error) {
        handleError(error)
        return
    }

    setSession(session)

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
    })

    return () => subscription.unsubscribe()
}

type SessionLoaderProps = {
    setSession: (value: Session | null) => void,
    isLoadingSession: boolean,
    setIsLoading: (value: boolean) => void
}

export default function SessionLoader(props: SessionLoaderProps) {
    const { handleError } = useError()

    useEffect(() => {
        async function doAsync() {
            await setSessionUp(props.setSession, handleError)
            props.setIsLoading(false)
        }

        if (props.isLoadingSession)
            doAsync()
    }, [handleError, props])

    return null
}
