import { createTheme } from "@mui/material"
import { blue, deepPurple, blueGrey, grey } from "@mui/material/colors"

declare module '@mui/material/styles' {
    // interface Theme {
    //     palette: {
    //         background?: {
    //             header?: string
    //         }
    //     }
    // }
    // allow configuration using `createTheme`
    interface TypeBackground {
        header?: string
        primary?: string
        contrastText?: string
    }
    interface TypeAction {
        mistake?: string
    }
}

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: deepPurple[700],
            light: deepPurple[400],
            dark: deepPurple[800]
        },
        background: {
            default: blueGrey[50],
            paper: blue[50],
            header: blue[100],
            primary: deepPurple[50],
            contrastText: grey[900]
        },
        action: {
            mistake: "#CC000060"
        }
    }
})
