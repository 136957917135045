import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import WordlistOptionsButtonMenu from "./WordlistOptionsButtonMenu"
import { Wordlist } from "../../types/wordlist/Wordlist"

export default function WordlistEntry({ wordlist, onRemove }: { wordlist: Wordlist, onRemove: () => void }) {
    const navigate = useNavigate()

    return (
        <Box display="flex" flexDirection="column" padding={2} minWidth="20%" sx={styles.boxBorder} bgcolor="background.primary">
            <Box display="flex">
                <Typography>{wordlist.name}</Typography>
                <Box flex={1} />
                <WordlistOptionsButtonMenu wordlist={wordlist} onRemove={onRemove} />
            </Box>
            <Button variant="contained" onClick={() => navigate("/study/" + wordlist.id)}>Study this list</Button>
        </Box>
    )
}

const styles = {
    boxBorder: {
        borderRadius: 1.5,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "black",
    }
}
