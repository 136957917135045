import { useNavigate, useParams } from 'react-router-dom'
import WordSearchbox from '../components/wordlistedit/WordSearchbox'
import { Box, Button, Container, Typography } from '@mui/material'
import useWordlistEdit from '../hooks/wordlist/useWordlistEdit'
import UpgradeModal from '../components/UpgradeModal'
import Loadable from '../components/Loadable'
import useWordlist from '../hooks/wordlist/useWordlist'
import useWordlistListEntries from '../hooks/wordlist/useWordlistListEntries'
import Subheader from '../components/Subheader'
import { Fragment } from 'react/jsx-runtime'
import WordlistEntriesList from '../components/wordlistedit/WordlistEntriesList'
import { DropResult } from 'react-beautiful-dnd'
import { useCallback } from 'react'

export default function WordlistEdit() {
    const params = useParams()
    const navigate = useNavigate()

    const { wordlist, wordlistLevel, wordlistContent, setWordlistContent } =
        useWordlist(Number(params.id))
    const { deleteItem, addWordToList, reorderWordlistContent, handleSubmit, upgradeModalIsOpen, setUpgradeModalIsOpen } =
        useWordlistEdit(wordlistContent, wordlistLevel?.id, setWordlistContent)
    const { wordlistListEntries, isLoading: isTableLoading, error: tableError } = useWordlistListEntries(wordlistContent, deleteItem)

    const onDragEnd = useCallback(({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return

        reorderWordlistContent(source.index, destination.index)
    }, [reorderWordlistContent])

    return (
        <Fragment>
            <Subheader title={`Edit Wordlist: ${wordlist?.name ?? ""}`} backAction={() => navigate("/")} />
            <Container maxWidth="xl">
                <Box margin="20px 0px">
                    {wordlist &&
                        <Typography>{wordlist.is_public ? "Public" : "Private"} wordlist</Typography>
                    }
                    <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" rowGap="20px">
                        <Loadable isLoading={isTableLoading} error={tableError}>
                            {
                                wordlistListEntries && <WordlistEntriesList wordlistListEntries={wordlistListEntries} onDragEnd={onDragEnd} />
                            }
                        </Loadable>
                        <WordSearchbox selectWord={addWordToList} />
                        <Box>
                            <Button variant="contained" type="submit">Save</Button>
                        </Box>
                    </Box>
                </Box>
                <UpgradeModal open={upgradeModalIsOpen} onClose={() => setUpgradeModalIsOpen(false)} />
            </Container>
        </Fragment>
    )
}
