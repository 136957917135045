import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../contexts/SessionContext"
import { supabase } from "../../api"
import { Wordlist } from "../../types/wordlist/Wordlist"
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"

export default function useWordlistList() {
    const { session } = useContext(SessionContext)
    const { handleError } = useError()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<PostgrestError>()
    const [wordlists, setWordlists] = useState<Wordlist[]>()

    useEffect(() => {
        async function doAsync() {
            const { data: wordlists, error } = await supabase.from("wordlist").select().eq("user_id", session.user.id).order("date_created")

            if (error) {
                handleError(error)
                setError(error)
                setIsLoading(false)
                return
            }

            setWordlists(wordlists)
            setIsLoading(false)
        }

        doAsync()
    }, [handleError, session.user.id])

    return { wordlists, setWordlists, isLoading, error }
}
