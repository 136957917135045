import { useCallback, useEffect, useState } from "react"
import { ReviewRecord } from "../../types/study/ReviewRecord"
import useWordlist from "../wordlist/useWordlist"
import { WordStudy } from "../../types/study/WordStudy"
import { advanceStudy, getNewReviewRecord, getUpdatedReviewRecord, initializeStudy } from "../../helpers/StudyHelper"
import { PrimitivesChallengeData, StandaloneChallengeData } from "../../types/study/ChallengeData"
import useError from "../useError"
import { supabase } from "../../api"
import useWarning from "../useWarning"
import { PostgrestError } from "@supabase/supabase-js"
import { DictionaryEntry } from "../../types/DictionaryEntry"

export default function useStudy(wordlistIdParameter: string, userId: string) {
    const wordlistId = Number(wordlistIdParameter)

    const {wordlist, wordlistContent, isLoading: wordlistIsLoading, error: wordlistError } = useWordlist(wordlistId)
    const [reviewRecords, setReviewRecords] = useState<ReviewRecord[]>()
    const [wordStudy, setWordStudy] = useState<WordStudy>()
    const [challengePosition, setChallengePosition] = useState<number>(0)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
    const [mistakes, setMistakes] = useState<number>(0)
    const [loadNextReview, setLoadNextReview] = useState<boolean>(true)
    const resetStudyState = () => {
        setWordStudy(undefined)
        setMistakes(0)
        setChallengePosition(0)
    }
    const { handleError } = useError()
    const { handleWarning } = useWarning()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | PostgrestError>()

    //#region Effects

    useEffect(() => {
        async function doAsync() {
            if (wordlistContent) {
                const { error } = await initializeStudy(userId, wordlistContent, setReviewRecords)
                if (error)
                    setError(error)
            }
        }

        doAsync()
    }, [userId, wordlistContent, wordlistIdParameter])

    useEffect(() => {
        async function maybeAdvanceStudy() {
            if (wordlistContent && reviewRecords && loadNextReview) {
                setIsLoading(true)
                resetStudyState()
                const { error, warnings } = await advanceStudy(wordlistContent, reviewRecords, setWordStudy)
                if (error) {
                    setError(error)
                    return
                }
                if (warnings) {
                    warnings.forEach(w => handleWarning(w))
                }

                setLoadNextReview(false)
                setIsLoading(false)
            }
        }

        maybeAdvanceStudy()
    }, [reviewRecords, wordlistContent, loadNextReview, handleWarning])

    useEffect(() => {
        async function recordReviewedRecord(reviewRecord: ReviewRecord) {
            const updatedRecord = getUpdatedReviewRecord(reviewRecord, mistakes)

            const { data: returnedRecord, error: postgresError } = await supabase.from("review_record").upsert(updatedRecord).select().single()
            if (postgresError) {
                setError(postgresError)
                return
            }

            setReviewRecords(prev => {
                const filteredPrevious = prev?.filter(r => r.id !== returnedRecord.id) ?? []
                return [...filteredPrevious, returnedRecord]
            })
        }

        async function doAsync() {
            if (!wordStudy?.challenges?.at(challengePosition)) {
                if (wordStudy?.reviewRecord)
                    await recordReviewedRecord(wordStudy?.reviewRecord)
            }
        }

        doAsync()
    }, [challengePosition, mistakes, wordStudy])

    useEffect(() => {
        if (error) {
            handleError(error)
            setIsLoading(false)
        }
    }, [error, handleError])

    //#endregion

    //#region Results

    const dictionaryEntry = wordStudy?.dictionaryEntry
    const challenge = wordStudy?.challenges?.at(challengePosition)
    const charactersShown = challenge?.position ?? 0

    const onMistake = useCallback(() => {
        setMistakes(prev => prev + 1)
        setBackdropOpen(true)
    }, [])

    const recordNewRecord = useCallback(async (dictionaryEntry: DictionaryEntry) => {
        const newRecord = getNewReviewRecord(userId, dictionaryEntry)

        const { data: returnedRecord, error: postgresError } = await supabase.from("review_record").upsert(newRecord).select().single()
        if (postgresError) {
            setError(postgresError)
            return
        }

        setReviewRecords(prev => {
            const filteredPrevious = prev?.filter(r => r.id !== returnedRecord.id) ?? []
            return [...filteredPrevious, returnedRecord]
        })
    }, [userId])

    const onContinue = useCallback(async () => {
        if (!wordStudy)
            return

        const standaloneChallenge = challenge as StandaloneChallengeData
        const primitivesChallenge = challenge as PrimitivesChallengeData
        if (standaloneChallenge?.falseChars || primitivesChallenge?.primitives) {
            setChallengePosition(prev => prev + 1)
            return
        }

        if (!wordStudy.reviewRecord) {
            await recordNewRecord(wordStudy.dictionaryEntry)
        }

        setLoadNextReview(true)
    }, [challenge, recordNewRecord, wordStudy])

    const setBackdropClosed = useCallback(() => {
        setBackdropOpen(false)
    }, [])

    //#endregion

    return {
        wordlist,
        dictionaryEntry,
        challenge,
        charactersShown,
        backdropOpen,
        onContinue,
        onMistake,
        setBackdropClosed,
        isLoading: wordlistIsLoading || isLoading,
        error: wordlistError ?? error
    }
}
