import { useState, FormEvent, useContext, useCallback } from "react"
import { WordlistContent, WordlistContentUpdate } from "../../types/wordlist/Wordlist"
import { DictionaryEntry } from "../../types/DictionaryEntry"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../api"
import { SessionContext } from "../../contexts/SessionContext"
import { getRolePermissionLimit } from "../../helpers/SessionHelper"
import useError from "../useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"
import { reorder } from "../../utils/arrayUtils"

async function updateWordlistContent(wordlistContent: WordlistContent[], contentToDelete: WordlistContent[]) {
    const newItems = wordlistContent.filter(c => !c.id)
    const { error: insertError } = await supabase.from('wordlist_content').insert(newItems)
    if (insertError)
        return { error: insertError }

    const updatedItems = wordlistContent.filter(c => c.id).map(
        c => {
            return { dictionary_entry_id: c.dictionary_entry_id, id: c.id, index: c.index, wordlist_level_id: c.wordlist_level_id } as WordlistContentUpdate
        }
    )
    // TODO: update when supabase supports bulk updates
    updatedItems.forEach(async c => {
        const { error: updateError } = await supabase.from('wordlist_content').update(c).eq('id', c.id!)
        if (updateError)
            return { error: updateError }
    })

    const { error: deleteError } = await supabase.from('wordlist_content').delete().in('id', contentToDelete.map(c => c.id))
    if (deleteError)
        return { error: deleteError }

    return {}
}

function reindexWordlistContent(wordlistContent: WordlistContent[]) {
    return wordlistContent.map((c, i) => ({ ...c, index: i }))
}

export default function useWordlistEdit(
    wordlistContent: WordlistContent[] | undefined,
    wordlistLevelId: number | undefined,
    setWordlistContent: (value: (value: WordlistContent[] | undefined) => WordlistContent[] | undefined) => void
) {
    const navigate = useNavigate()
    const { session } = useContext(SessionContext)
    const { handleError } = useError()
    const [contentToDelete, setContentToDelete] = useState<WordlistContent[]>([])
    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
    const { snackbarSuccess } = useContext(SnackbarContext)

    const deleteItem = useCallback((item: WordlistContent) => {
        if (item.id)
            setContentToDelete(content => [...content, item])

        setWordlistContent(content => {
            const filteredContent = content?.filter(c => c !== item) ?? []
            return reindexWordlistContent(filteredContent)
        })
    }, [setWordlistContent])

    const addWordToList = useCallback(async (newWord: DictionaryEntry | null) => {
        if (!wordlistContent || !wordlistLevelId || !newWord)
            return

        const { limit, error } = await getRolePermissionLimit(session, "wordlist_content.creation")
        if (error) {
            handleError(error)
            return
        }

        if (limit && wordlistContent.length >= limit) {
            setUpgradeModalIsOpen(true)
            return
        }

        const lastIndex = wordlistContent[wordlistContent.length - 1]?.index ?? -1
        const newItem: WordlistContent = { dictionary_entry_id: newWord.id, wordlist_level_id: wordlistLevelId, index: lastIndex + 1, }
        setWordlistContent(content => [...content ?? [], newItem])
    }, [handleError, session, setWordlistContent, wordlistContent, wordlistLevelId])

    const reorderWordlistContent = useCallback((originIndex: number, destinationIndex: number) => {
        setWordlistContent(prev => {
            if (!prev)
                return prev

            return reindexWordlistContent(reorder(prev, originIndex, destinationIndex))
        })
    }, [setWordlistContent])

    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!wordlistContent)
            return

        const { error } = await updateWordlistContent(wordlistContent, contentToDelete)
        if (error) {
            handleError(error)
            return
        }

        navigate("/")
        snackbarSuccess("Wordlist updated.")
    }, [contentToDelete, handleError, navigate, snackbarSuccess, wordlistContent])

    return { deleteItem, addWordToList, reorderWordlistContent, handleSubmit, upgradeModalIsOpen, setUpgradeModalIsOpen }
}
