import { useEffect, useState } from "react"
import { Wordlist, WordlistContent, WordlistLevel } from "../../types/wordlist/Wordlist"
import { supabase } from '../../api'
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"

export default function useWordlist(wordlistId: number) {
    const [wordlist, setWordlist] = useState<Wordlist>()
    const [wordlistLevel, setWordlistLevel] = useState<WordlistLevel>()
    const [wordlistContent, setWordlistContent] = useState<WordlistContent[]>()
    const [error, setError] = useState<Error | PostgrestError>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { handleError } = useError()

    useEffect(() => {
        async function doAsync() {
            if (Number.isNaN(wordlistId)) {
                const numberError = new Error("Wordlist not found! Please verify the URL.")
                handleError(numberError)
                setError(numberError)
                setIsLoading(false)
                return
            }

            const { data: wordlist, error: wordlistError } = await supabase.from('wordlist').select().eq("id", wordlistId).single()
            if (wordlistError) {
                handleError(wordlistError)
                setError(wordlistError)
                setIsLoading(false)
                return
            }
            setWordlist(wordlist)

            const { data: wordlistLevel, error: wordlistLevelError } = await supabase.from('wordlist_level').select().eq("wordlist_id", wordlistId).single()
            if (wordlistLevelError) {
                handleError(wordlistLevelError)
                setError(wordlistLevelError)
                setIsLoading(false)
                return
            }
            setWordlistLevel(wordlistLevel)

            const { data: wordlistContent, error: wordlistContentError } =
                await supabase.from('wordlist_content').select().eq("wordlist_level_id", wordlistLevel.id).order("index", { ascending: true })
            if (wordlistContentError) {
                handleError(wordlistContentError)
                setError(wordlistContentError)
                setIsLoading(false)
                return
            }
            setWordlistContent(wordlistContent)

            setIsLoading(false)
        }

        doAsync()
    }, [handleError, wordlistId])

    return { wordlist, wordlistLevel, wordlistContent, setWordlistContent, isLoading, error }
}
