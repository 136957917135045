export function insertRandomly(array: any[], newItem: any) {
    array.push(newItem)
    if (array.length === 1)
        return

    const random = Math.floor(Math.random() * array.length)
    const swap = array[random]
    array[random] = newItem
    array[array.length - 1] = swap
}

export function reorder(array: any[], originIndex: number, destinationIndex: number) {
    const result = Array.from(array)
    const [removed] = result.splice(originIndex, 1)
    result.splice(destinationIndex, 0, removed)
    return result
}
