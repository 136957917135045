import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import { WordlistListEntry } from "./WordlistEntriesList"
import { Draggable } from 'react-beautiful-dnd'
import WordlistItemOptionsButtonMenu from "./WordlistItemOptionsButtonMenu";

export default function WordlistEntriesListItem({ wordlistListEntry }: { wordlistListEntry: WordlistListEntry }) {
    const primaryText = wordlistListEntry.dictionaryEntry.dictionary_word[0]?.word ??
        wordlistListEntry.dictionaryEntry.dictionary_reading[0]?.reading

    const secondaryText = wordlistListEntry.dictionaryEntry.dictionary_reading[0]?.reading

    const translation = wordlistListEntry.dictionaryEntry.dictionary_meaning[0]?.dictionary_meaning_translation[0]?.translation ?? "-"

    const even = wordlistListEntry.index % 2 === 0

    return (
        <Box maxWidth={500}>
            <Draggable draggableId={wordlistListEntry.dictionaryEntry.id.toString()} index={wordlistListEntry.index}>
                {(provided) => (
                    <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Box bgcolor={even ? "action.hover" : undefined} display="flex" flexDirection="row" minWidth={900}>
                            <Typography alignSelf="center" textAlign="center" width={50} >{wordlistListEntry.index + 1}</Typography>
                            <ListItemText primary={primaryText} secondary={secondaryText} />
                            <Typography alignSelf="center" width={400} >{translation}</Typography>
                            <WordlistItemOptionsButtonMenu deleteItem={wordlistListEntry.deleteItem} />
                        </Box>
                    </ListItem>
                )}
            </Draggable >
        </Box>
    )
}
