import { User } from '@supabase/supabase-js'
import { Box, Button, Link } from '@mui/material'
import { Report, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import ReportModal from './ReportModal'
import UserAccountOptionsButtonMenu from './UserAccountOptionsMenu'

export default function Header({ user }: { user: User | undefined }) {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [isIssue, setIsIssue] = useState<boolean>(false)

    // TODO: adjust for Material UI
    return (
        <React.Fragment>
            <Box width="auto" columnGap={2} display="flex" paddingX={3} paddingY={1} bgcolor="background.header" >
                <Link href="/" display="flex" color="text.primary" sx={{ textDecoration: "none" }}>
                    <img src="/logo192.png" alt="logo" style={styles.logo} />
                    <h3 style={styles.logoText}>
                        Kanji Puzzle
                    </h3>
                </Link>
                <Box display={"flex"} flex={1} />
                <Button variant="outlined" onClick={() => { setModalOpen(true); setIsIssue(true) }} startIcon={<Report />}>
                    Report an issue
                </Button>
                <Button variant="outlined" onClick={() => { setModalOpen(true); setIsIssue(false) }} startIcon={<Info />}>
                    Submit a suggestion
                </Button>
                {/* <LightDarkButton /> */}
                {user && <UserAccountOptionsButtonMenu />}
            </Box>
            <ReportModal open={modalOpen} onClose={() => setModalOpen(false)} isIssue={isIssue} />
        </React.Fragment>
    )
}

const styles = {
    logo: {
        width: "2.5rem",
        height: "2.5rem"
    },
    logoText: {
        marginLeft: 10,
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center",
        marginTop: 0,
        marginBottom: 0,
    }
}
