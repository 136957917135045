import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Fragment, useCallback, useContext, useState } from "react"
import { Wordlist } from "../../types/wordlist/Wordlist"
import { useNavigate } from "react-router-dom"
import ConfirmationModal from "../ConfirmationModal"
import { supabase } from "../../api"
import useError from "../../hooks/useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"

export default function WordlistOptionsButtonMenu({ wordlist, onRemove }: { wordlist: Wordlist, onRemove: () => void }) {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClickEdit = () => {
        navigate("/wordlists/edit/" + wordlist.id)
        handleClose()
    }

    const handleClickDelete = () => {
        setConfirmationModalOpen(true)
        handleClose()
    }

    const handleDelete = useCallback(async () => {
        const { data, error } = await supabase.from("wordlist").delete().eq("id", wordlist.id).select().single()
        if (error) {
            handleError(error)
            return
        }

        navigate("/")
        snackbarSuccess(`${data.name} has been deleted.`)
        onRemove()
    }, [handleError, navigate, onRemove, snackbarSuccess, wordlist.id])

    return (
        <Fragment>
            <IconButton aria-label="options" onClick={handleClick}><MoreHorizIcon /></IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClickEdit}>Edit Wordlist</MenuItem>
                <MenuItem onClick={handleClickDelete} sx={{ backgroundColor: "error.main" }}>Delete Wordlist</MenuItem>
            </Menu>
            <ConfirmationModal
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                title={`Delete ${wordlist.name}?`}
                text="Warning! This will delete all words in the list. This action cannot be undone."
                confirmAction={handleDelete}
            />
        </Fragment>
    )
}
