import { ArrowBack } from "@mui/icons-material";
import { Box, Container, Divider, IconButton, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

type SubheaderProps = {
    title: string,
    backAction?: () => void
}

export default function Subheader(props: SubheaderProps) {
    return (
        <Box bgcolor="background.header">
            <Container maxWidth="xl">
                <Box display="flex" columnGap={2} paddingY={1}>
                    {props.backAction &&
                        <Fragment>
                            <IconButton aria-label="back" onClick={props.backAction}>
                                <ArrowBack sx={{ fontSize: 25 }} />
                            </IconButton>
                            <Divider orientation="vertical" flexItem />
                        </Fragment>
                    }
                    <Typography variant="h4" color="background.contrastText">{props.title}</Typography>
                </Box>
            </Container>
        </Box>)
}
