import WordlistEntry from '../components/dashboard/WordlistEntry'
import { Box, Container, IconButton } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import UpgradeModal from '../components/UpgradeModal'
import NewWordlistModal from '../components/dashboard/NewWordlistModal'
import useWordlistList from '../hooks/wordlist/useWordlistList'
import { Wordlist } from '../types/wordlist/Wordlist'
import useOpenNewWordlistModal from '../hooks/wordlist/useOpenNewWordlistModal'
import Loadable from '../components/Loadable'
import Subheader from '../components/Subheader'
import { Fragment } from 'react/jsx-runtime'

export default function Dashboard() {
    const { wordlists, setWordlists, isLoading, error } = useWordlistList()
    const {
        tryOpenNewWordlistModal,
        newWordlistModalIsOpen,
        setNewWordlistModalIsOpen,
        upgradeModalIsOpen,
        setUpgradeModalIsOpen
    } = useOpenNewWordlistModal(wordlists)

    return (
        <Fragment>
            <Subheader title="Dashboard" />
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" rowGap="20px" margin={4}>
                    <Loadable isLoading={isLoading} error={error}>
                        {wordlists?.map((w: Wordlist, i: number) =>
                            <WordlistEntry key={i} wordlist={w} onRemove={() => setWordlists(prev => prev?.filter(p => p.id !== w.id) ?? [])} />
                        )}
                    </Loadable>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <IconButton aria-label="logout" onClick={tryOpenNewWordlistModal}>
                        <AddCircle sx={{ fontSize: 50 }} color="success" />
                    </IconButton>
                </Box>
                <NewWordlistModal open={newWordlistModalIsOpen} onClose={() => setNewWordlistModalIsOpen(false)} />
                <UpgradeModal open={upgradeModalIsOpen} onClose={() => setUpgradeModalIsOpen(false)} />
            </Container >
        </Fragment>)
}
