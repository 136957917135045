import { useCallback, useState, useContext } from "react"
import { getRolePermissionLimit } from "../../helpers/SessionHelper"
import { SessionContext } from "../../contexts/SessionContext"
import { Wordlist } from "../../types/wordlist/Wordlist"
import { SnackbarContext } from "../../contexts/SnackbarContext"
import useError from "../useError"

export default function useOpenNewWordlistModal(wordlists: Wordlist[] | undefined) {
    const { session } = useContext(SessionContext)
    const { snackbarWarning } = useContext(SnackbarContext)
    const { handleError } = useError()
    const [newWordlistModalIsOpen, setNewWordlistModalIsOpen] = useState(false)
    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)

    const tryOpenNewWordlistModal = useCallback(async () => {
        if (!wordlists) {
            snackbarWarning("Issue loading existing wordlists... please try reloading the page.")
            return
        }

        const { limit, error } = await getRolePermissionLimit(session, "wordlist.creation")
        if (error) {
            handleError(error)
            return
        }

        if (limit && wordlists.length >= limit) {
            setUpgradeModalIsOpen(true)
            return
        }

        setNewWordlistModalIsOpen(true)
    }, [handleError, session, snackbarWarning, wordlists])

    return {
        tryOpenNewWordlistModal,
        newWordlistModalIsOpen,
        setNewWordlistModalIsOpen,
        upgradeModalIsOpen,
        setUpgradeModalIsOpen
    }
}
